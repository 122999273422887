import * as React from "react"
import Layout from "../components/layout"
import { allVst, vstSection } from "./lotusdsp.module.css"

const LotusDspPage = () => {
  return (
    <Layout pageTitle="LotusDSP" pageHeader="VST Plugins">
      <div className={allVst}>
        <div className={vstSection}>
          <h2>LotusDecimator</h2>
          <p>Single knob decimation</p>
          <p>Version 0.2 5/2/19</p>
          <ul>
            <li>
              <a href="/storage/LotusDecimator.dll">64-bit VST2</a>
            </li>
            <li>
              <a href="/storage/LotusDecimator.vst3">64-bit VST3</a>
            </li>
          </ul>
        </div>

        <div className={vstSection}>
          <h2>LotusDelay</h2>
          <p>Simple delay effect</p>
          <p>Version 0.1 4/21/19</p>
          <ul>
            <li>
              <a href="/storage/LotusDelay.vst3">64-bit VST3</a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default LotusDspPage
