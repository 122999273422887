import * as React from "react"
import "@fontsource/nunito/200.css"
import "@fontsource/nunito/600.css"
import { Link } from "gatsby"
import {
  container,
  titleAndContentDiv,
  heading,
  mainDiv,
  navLinkItem,
  navLinks,
  navLinkText,
} from "./layout.module.css"

interface LayoutProps {
  pageTitle: string
  pageHeader?: string
  children: React.ReactNode
}

const Layout = ({ pageTitle, pageHeader, children }: LayoutProps) => {
  return (
    <div className={container}>
      <title>{pageTitle}</title>
      <div className={mainDiv}>
        <nav>
          <ul className={navLinks}>
            <li className={navLinkItem}>
              <Link to="/" className={navLinkText}>
                Home
              </Link>
            </li>
            <li className={navLinkItem}>
              <Link to="/lotusdsp" className={navLinkText}>
                LotusDSP
              </Link>
            </li>
            <li className={navLinkItem}>
              <Link to="/blog" className={navLinkText}>
                Blog
              </Link>
            </li>
            <li className={navLinkItem}>
              <a
                href="https://www.soundcloud.com/skizye"
                className={navLinkText}
              >
                Skizye
              </a>
            </li>
            <li className={navLinkItem}>
              <a href="https://www.github.com/jtbeckha" className={navLinkText}>
                Github
              </a>
            </li>
          </ul>
        </nav>
        <div className={titleAndContentDiv}>
          {pageHeader && <h1 className={heading}>{pageHeader}</h1>}
          <main>{children}</main>
        </div>
      </div>
    </div>
  )
}

export default Layout
